/* main.css */
* {
  box-sizing: border-box;
}
body, html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.App {
  text-align: left;
}

.App.light {
  background-color: #fff;
  color: #000;
}

.App.dark {
  background-color: #333;
  color: #fff;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjusted for spacing */
  padding: 15px 20px; /* Adjusted padding for a balanced look */
  background: transparent;
  width: 100%;
  position: fixed;
  top: 0; /* Ensure navbar is at the top */
  left: 0; /* Align to the left */
  z-index: 1050; /* Ensure navbar is above other content */
}

.logo img {
  width: auto;
  height: 50px; /* Adjust based on your logo's aspect ratio */
  margin-right: auto; /* Pushes everything else to the right */
}
  
  .nav-links {
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    list-style: none;
    margin: 0; /* Removes default margin */
    margin-left: 8px
  }
  
  .nav-links li {
    margin-right: 20px; /* Adjust spacing between items */
    font-size: 1.2rem; /* Makes items larger */
  }
  
  .nav-links li a {
    color: #fff; /* Inherits color from parent, adjust as needed */
    text-decoration: none; /* Removes underline by default */
    cursor: pointer; /* Changes cursor to pointer */
    font-size: 1.5rem; /* Adjust this value as needed to make the text larger */
    font-weight: bold;
  }
  
  .nav-links li a:hover, .nav-links li a:focus {
    text-decoration: underline; /* Adds underline on hover/focus */
  }

  .hamburger {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
  }
  
  .hamburger div {
    width: 30px;
    height: 3px;
    background-color: #fff;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1000; /* Ensure it's below the sidebar but above other content */
  }
  .nav-links.open {
    transform: translateX(0);
  }

.navbar-scrolled {
background-color: rgba(0, 0, 0, 0.9); /* Change to your preferred shade of black */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.hero-section img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  bottom: 30%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 5%; /* Example proportional padding */
}

.hero-content h1 {
  color: #fff; /* Set text color to white */
  font-family: 'Times New Roman', serif; /* Use Times New Roman font */
  font-size: 4.9vw; /* Example responsive font size, adjust as needed */
  white-space: pre-wrap; /* Allows text to wrap */
  word-wrap: break-word; /* Ensures long words do not overflow */
  position: relative; /* Needed for the cursor simulation */
}

.hero-content h1::after {
  content: '|';
  color: #f56a6a; /* Set cursor color */
  font-weight: bold; /* Optional: Makes the cursor more visible */
  animation: blink-caret .75s step-end infinite;
  position: absolute;
}
  
  /* Typing animation */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  @keyframes blink-caret {
    from, to { opacity: 0; }
    50% { opacity: 1; }
  }
  .hero-content.button-container {
    bottom: 40px;
    justify-content: left; /* Aligns content to the left */
    text-align: left;
  }

  .hero-content button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1); /* White text with low opacity background */
    color: #fff; /* Text color remains white */
    border: 2px solid #f56a6a; /* Thick #f56a6a border */
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; /* Updated to include border-color transition */
}

.hero-content button:hover {
    background-color: #f56a6a; /* #f56a6a background */
    color: #fff; /* White text */
    border-color: #fff; /* White border */
    /* No transition delay specified, so changes happen instantly on hover */
}

.hr-style {
  height: 7px; /* Makes the line thicker */
  background-color: #000; /* Sets the color of the line */
  border: none; /* Removes any default border */
}

.public-projects-section, .worked-with-section, .who-we-are-section {
  padding: 40px 20px;
}

.projects-container, .worked-with-container, .who-we-are-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.worked-with-container {
  gap: 60px; /* Increases the gap between items for more space */
  padding-top: 80px; /* Adds consistent padding above and below the container */
}
.section-heading, .section-subheading {
  flex-basis: 50%;
  text-align: left;
}
.section-heading {
  font-family: sans-serif; /* Example font, replace with your choice */
  font-size: 3.5rem; /* Larger size */
  text-align: left;
  margin-block-start: 10px;
}
.section-subheading {
  font-family: sans-serif; /* Example font, replace with your choice */
  font-size: 2.5rem; /* Larger size */
  text-align: left;
  margin-block-start: 10px;
  padding-bottom: 100px; /* Padding below heading text */
  padding-left: 20px;
}

.project {
  flex-basis: calc(25% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: flex-start; /* Align items to the top */
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  color: black;
  border: 2px solid black; /* Black square border */
  padding: 10px;
  box-sizing: border-box;
  width: 250px; /* Adjust based on your preference for square size */
  height: 250px; /* Keeps the container square */
  margin-bottom: 20px; /* Adds some space below each project */
  position: relative; /* Needed for absolute positioning of children */
}

.project img {
  width: 50px !important; /* Adjust based on your preference for icon size */
  height: 50px !important; /* Adjust based on your preference for icon size */
  object-fit: contain !important; /* Ensures the entire image is visible without being cut off */
  position: absolute; /* Position the image absolutely within its container */
  bottom: 10px; /* Distance from the bottom of the container */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust horizontal position to truly center */
}

.project:hover {
  transform: scale(1.05);
}

.worked-with-company {
  /* flex-basis: calc(33.333% - 40px); Adjusts the width of each company block, accounting for the gap */
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Centers the content vertically */
  text-align: center;
}

.worked-with-company img {
  height: 100px; /* Example height, adjust as needed */
  width: auto; /* Adjusts based on the image's aspect ratio */
  object-fit: contain; /* Ensures the entire image is visible without being cut off */
}

.project, .worked-with-company {
  flex-basis: calc(25% - 20px);
  text-align: center;
}

.project img, .worked-with-company img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

/* Adjust the base size of the images for larger display */
.worked-with-company img, .project img {
    max-width: 400px; /* Adjust based on your preference */
    max-height: 100px;
    object-fit: contain !important;
    margin-bottom: 10px;
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }
  
  /* Ensure the anchor tags are styled properly for the hover effect */
  .worked-with-company a, .project a {
    text-decoration: none; /* Removes underline from links */
    color: inherit; /* Inherits text color from parent elements */
  }

.who-we-are-column {
  flex-basis: calc(50% - 20px);
  text-align: center;
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
}
.who-we-are-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin: 20px 0;
}

.founders {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.founder {
  max-width: 350px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center; /* This ensures that all content (including images) is centered horizontally */
  justify-content: center; /* This vertically centers content if the container's height is explicitly set */
  text-align: center; /* Ensures text is centered, useful for non-flex children */
  width: 100%; /* Ensures the div takes up the full width of its parent, aiding in centering */
  box-sizing: border-box; /* Ensures padding and borders are included in the width & height */
}

.founder-image {
  width: auto; /* Adjusts width automatically to maintain aspect ratio */
  height: auto; /* Adjusts height automatically to maintain aspect ratio */
  max-width: 100%; /* Ensures the image does not exceed its container's width */
  max-height: 100%; /* Ensures the image does not exceed its container's height */
  border-radius: 50%;
  object-fit: cover; /* Ensures the image covers the area without losing its aspect ratio */
  margin: 0 auto; /* Additional reinforcement for horizontal centering */
}
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.get-in-touch-container {
  background-color: black;
  color: white;
  padding: 60px; /* Increased padding */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 30px; /* Increased gap */
  width: 100%;
  max-width: 600px; /* Increased max-width */
  margin-bottom: 100px;
  margin-top: 100px;
}

.contact-form h2 {
  color: #ffffff;
  text-align: center;
  font-size: 2.5rem; /* Increased font size */
}

.contact-form h3 {
  color: #ffffff;
  text-align: center;
  font-size: 2rem; /* Increased font size */
}

.contact-form input,
.contact-form textarea {
  padding: 15px 20px; /* Increased padding */
  border: 2px solid #ffffff; /* Adjusted border thickness */
  background-color: #000000;
  color: #ffffff;
  border-radius: 8px; /* Adjusted border-radius */
  font-size: 1.5rem; /* Increased font size */
  font-family: 'Times New Roman', serif;
}
.contact-form textarea {
  height: 250px;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  font-family: 'Times New Roman', serif;
  color: #ffffff;
  font-size: 1.2rem; /* Increased font size for placeholders */
}

.contact-form button {
  padding: 15px 25px; /* Increased padding */
  font-size: 1.5rem; /* Increased font size */
  cursor: pointer;
  background-color: transparent; /* Use transparent background */
  color: #fff; /* Text color is white */
  border: 3px solid #fff; /* Increased border thickness */
  border-radius: 8px; /* Adjusted border-radius */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for background and color */
}

.contact-form button:hover {
  background-color: #f56a6a; /* Change background color on hover */
  color: #fff; /* White text */
  border-color: #fff; /* White border */
}

.hr-footer-container {
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 1px;
}

/* Adjusting the hr-footer-style for a solid white line */
.hr-footer-style {
  border: 0;
  height: 1px;
  margin: 0; /* Optional: Adjusts the margin around the <hr> if needed */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
  background-color: #000;
  color: #fff;
  padding-bottom: 100px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
}

.logo-column, .back-to-top-column {
  width: 33.333%;
  padding: 0 20px; /* Added padding for left and right text */
  font-family: sans-serif; /* Ensuring font-family is Arial, sans-serif */
}

.back-to-top-column {
  width: 33.333%;
  align-items: flex-end;
}

.social-icon {
  width: 50px; /* Adjust as needed */
  height: auto;
  margin: 0 5px;
  z-index: 10;
}
.social-icons-container {
  display: flex;
  justify-content: center; /* Centers the icons horizontally */
  align-items: center; /* Aligns the icons vertically, useful if they have different heights */
  gap: 20px; /* Adds some space between the icons */
  margin: 20px 0; /* Adds vertical margin for spacing above and below the icon container */
}

.social-icon:hover {
  opacity: 0.8;
}

.back-to-top-link {
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: #fff; /* Adjust as needed */
}

.back-to-top-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Keeps items on either end */
    padding: 35px 20px;
    background: transparent;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
  }
  .navbar-scrolled {
    background-color: rgba(0, 0, 0, 0.9); /* Change to your preferred shade of black */
    }
  .logo {
    position: absolute;
    left: 80%;
    padding-top: 10px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%; /* Ensures it can be centered regardless of the siblings */
    z-index: 1020; /* Lower than hamburger to not interfere with click */
  }
  
  .logo img {
    height: 50px;
    margin: 0 auto; /* Centers the image within the .logo div */
  }
  
  .hamburger {
    z-index: 1100; /* Ensures visibility above other elements */
  }
  .hamburger {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    z-index: 1100; /* Higher z-index to ensure visibility */
  }
  
  /* Ensure the hamburger menu is visible above the sidebar */
  .hamburger div {
    background-color: #fff; /* Adjust color as needed */
  }
  .nav-links {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    padding-top: 60px;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    padding-top: 200px; /* Adds padding above the sidebar text */
    padding-left: 20px; /* Add padding to the left to ensure items are not touching the edge */
  }
  .nav-links li {
    color: #fff;
    padding: 10px;
  }
  .hero-content {
    padding-left: 3%; /* Reduced padding for smaller screens */
  }
  .hero-content.button-container {
    left: 50%;
    transform: translateX(-50%);
    padding: 0 15px; /* Add padding to ensure content does not touch the edges */
  }
  .hero-content h1 {
    font-size: 3rem; /* Adjust font size for smaller screens */
    white-space: normal; /* Allows text to wrap */
  }
  .hero-content h1::after {
    content: '|';
    color: #f56a6a; /* Set cursor color */
    font-weight: bold; /* Optional: Makes the cursor more visible */
    animation: blink-caret .75s step-end infinite;
    position: absolute;
  }
  .hero-content.button-container {
    left: 50%; /* Initially moves the button to the center */
    transform: translateX(-50%); /* Correctly centers the button */
    justify-content: center; /* Ensures the flex container is centered */
    text-align: center;
  }
  .public-projects-section, .worked-with-section, .who-we-are-section {
    padding: 40px 20px; /* Reduced padding for smaller screens */
  }
  .section-heading {
    font-size: 2.5rem; /* Larger size */
  }
  .section-subheading {
    font-size: 1.5rem; /* Larger size */
    padding-left: 0;
  }
  .project, .worked-with-company {
    flex-basis: calc(50% - 20px);
  }
  .worked-with-company img {
    max-width: 100%; /* Ensure images do not overflow their containers */
    height: auto;
  }
  .project img {
    display: none; /* Hide project images on mobile */
  }
  .worked-with-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
    gap: 60px; /* Maintain gap between items */
    padding: 20px 0; /* Add some padding on top and bottom */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scrollbar-width: none; /* Hides scrollbar for Firefox */
    -ms-overflow-style: none;  /* Hides scrollbar for Internet Explorer and Edge */
  }
  .worked-with-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  .worked-with-company {
    flex: 0 0 auto; /* Do not grow, do not shrink, and is automatically sized based on its width */
    width: 80%; /* Adjust based on your preference */
    max-width: 250px; /* Maximum width of each company block */
  }
  .who-we-are-section .who-we-are-column {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px 0;
  }
  .who-we-are-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .who-we-are-section .who-we-are-column .section-heading,
  .who-we-are-section .who-we-are-column .section-subheading {
    text-align: left;
    width: 100%; /* Ensure full width for proper alignment */
    align-self: flex-start; /* Aligns the text to the start */
  }

  .who-we-are-section .section-heading,
  .who-we-are-section .section-subheading {
    flex-basis: 100%;
  }
  .who-we-are-section .who-we-are-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the direct children */
    margin: 20px 0;
  }
  .who-we-are-section .who-we-are-content > div {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center everything in this div, including images */
  }
  .who-we-are-section .who-we-are-content div > div {
    width: 250px; /* Smaller width for mobile */
    height: 250px; /* Adjust height accordingly */
    margin-bottom: 120px; /* Add some space between the items */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .who-we-are-section .who-we-are-content div > div img {
    max-width: 100%; /* Ensures the image is no wider than its container */
    height: auto; /* Maintains the aspect ratio of the image */
    border-radius: 50%; /* Keeps the circular shape */
    object-fit: cover; /* Ensures the image covers the area without losing its aspect ratio */
  }
  .who-we-are-section img {
    border-radius: 50%; /* Maintain circular shape */
    object-fit: cover; /* Ensure the aspect ratio is maintained */
    width: 100%; /* Make the image responsive */
    height: 100%; /* Ensure the height matches the new width */
    justify-content: center
  }
  .get-in-touch-container {
    margin-bottom: 10px; /* Adds space below the contact form on mobile */
  }
  .contact-form {
    gap: 10px; /* Reduced gap for smaller screens */
    padding: 0 0px; /* Add padding to ensure content does not touch the edges */
  }
  .hr-footer-container {
    padding-top: 0px;
  }
  /* Adjusting the hr-footer-style for a solid white line */
  .hr-footer-style {
    border: 0;
    height: 0px;
    margin: 0; /* Optional: Adjusts the margin around the <hr> if needed */
  }
  .footer-container {
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }
  .footer-column, .logo-column, .back-to-top-column, .social-column {
    width: 100%;
    align-items: center;
    padding: 0 15px; /* Add padding to ensure content does not touch the edges */
  }
  .footer-column {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }

  .back-to-top-column {
    align-items: center;
  }
}
